import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ReactStorage, useList} from '../store';

const Scoreboard = () => {
    const blocks = useList('blocks' as Extract<keyof ReactStorage, string>);
    const [plagScore, setPlagScore] = useState<string>(null);
    const [AIscore, setAIScore] = useState<{ original: number; ai: number }>({
        original: null,
        ai: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkButtonPressed, setCheckButtonPressed] = useState<boolean>(false);
    const [buttonCLickData, setbuttonCLickData] = useState<any>(null);

    const handleCheckScore = () => {
        setIsLoading(true);
        setCheckButtonPressed(true);
        let editorContent = '';
        if (blocks) {
            const blockItems = Object.values(blocks);
            //@ts-ignore
            const blockList = blockItems[1] as LiveRegister[];
            for (let i = 0; i < blockList.length; i++) {
                console.log(blockList[i]._data.children);
                for (let j = 0; j < blockList[i]._data.children.length; j++) {
                    console.log(blockList[i]._data.children[j].text);
                    editorContent += blockList[i]._data.children[j].text;
                }
            }
            console.log('editorContent is: ', editorContent);

            // below is code to send request call to API
            const requestOpt = {
                method: 'POST',
                headers: {
                    'X-OAI-API-KEY': 'nlxm0gckhtopr2ue81szdq54ai3y7vjf',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: editorContent,
                }),
            };

            // below is code to get response from API after sending request
            fetch('https://api.originality.ai/api/v1/scan/ai-plag', requestOpt)
                .then((response) => response.json()) // if the response is a JSON object
                .then((data) => {
                    console.log('apiResponse is: ', data);
                    setPlagScore(data.plagiarism.total_text_score);
                    setAIScore(data.ai.score);
                    setIsLoading(false);
                    setCheckButtonPressed(true)
                }).then(() => {
                setbuttonCLickData({
                    type: "score-check",// for “check again” button that runs originality api
                    length_characters: editorContent.length.toString(),
                    score_ai: (AIscore.ai !== null) ? (AIscore.ai*100).toString()+"%" : "0",
                    score_plagiarism: (plagScore !== null) ? plagScore.toString() : "0",
                    logged_in: "yes",
                    paid_user: "idk"
                })
            })
                .catch((err) =>
                    console.error('error:' + err, 'got error from fetch')
                );
        }
    };

    console.log('event: "button_click", data:', buttonCLickData);

    return (
        <div className={'fixed top-24 ml-28'}>

            {
                (isLoading) ? <div className={'flex flex-row mt-7 mb-5 ml-12'}>
                    <span className={'pt-1 -ml-12 text-2xl text-slate-500'}>Calculating</span>
                    <img src={'/images/loadingDots.gif'} width={50} className={'-mt-1'}/>
                </div> : <></>
            }

            {AIscore && (
                <div className={''}>

                    {(plagScore === null) ?
                        <div className={'flex flex-row mb-3'}>
                            <div className="relative group">
                                <img src="/images/infoButton.png" className="w-6 h-6 group-hover:block"
                                     alt="Tooltip icon"/>
                                <div
                                    className="absolute -mt-2 ml-6 z-10 hidden group-hover:block bg-gray-700 text-gray-100 px-9 py-9 rounded rounded-lg rounded-tl-none shadow-lg">
                                    <p className={'w-52 text-sm'}>Plagiarism score measures how much of a text is copied
                                        from other sources or how much similarity you have with other sources.More
                                        similarity with other sources, higher score %. To create unique content, try to
                                        have Plagiarism score as low as possible</p>
                                </div>
                            </div>
                            <span className={'pb-2'}>Plagiarism Score: </span>
                        </div> :
                        <div className={'flex flex-row'}>
                            <p className={'pb-2 -ml-2'}>Plagiarism Score: {plagScore}</p>
                            <div className="relative group">
                                <img src="/images/infoButton.png" className="w-6 h-6 group-hover:block"
                                     alt="Tooltip icon"/>
                                <div
                                    className="absolute -mt-2 ml-6 z-10 hidden group-hover:block bg-gray-700 text-gray-100 px-9 py-9 rounded rounded-lg rounded-tl-none shadow-lg">
                                    <p className={'w-52 text-sm'}>Plagiarism score measures how much of a text is copied
                                        from other sources or how much similarity you have with other sources.More
                                        similarity with other sources, higher score %. To create unique content, try to
                                        have Plagiarism score as low as possible</p>
                                </div>
                            </div>
                        </div>
                    }
                    {(AIscore.ai === null) ?
                        <div className={'flex flex-row'}>
                            <div className="relative group">
                                <img src="/images/infoButton.png" className="w-6 h-6 group-hover:block"
                                     alt="Tooltip icon"/>
                                <div
                                    className="absolute -mt-2 ml-6 z-10 hidden group-hover:block bg-gray-700 text-gray-100 px-9 py-9 rounded rounded-lg rounded-tl-none shadow-lg">
                                    <p className={'w-52 text-sm'}>AI Detection score stands for how much AI help you you
                                        have taken and how much % of your content is by AI</p>
                                </div>
                            </div>
                            <span className={'pb-2'}>AI Detection Score: </span>
                        </div> :
                        <div className={'flex flex-row'}>
                            <p className={'pb-2 -ml-2'}>AI Detection Score: {AIscore.ai * 100}%</p>
                            <div className="relative group">
                                <img src="/images/infoButton.png" className="w-6 h-6 group-hover:block"
                                     alt="Tooltip icon"/>
                                <div
                                    className="absolute -mt-2 ml-6 z-10 hidden group-hover:block bg-gray-700 text-gray-100 px-9 py-9 rounded rounded-lg rounded-tl-none shadow-lg">
                                    <p className={'w-52 text-sm'}>AI Detection score represents how much AI help you you
                                        have taken and how much % of your content is by AI</p>
                                </div>
                            </div>
                        </div>}

                </div>
            )}

            {isLoading ? <></> : <>
                <button
                    className={'checkButton ml-5 mt-5 border border-2 bg-[#1CCAFF] rounded-3xl text-black hover:bg-black hover:text-white font-bold py-2 px-4 rounded'}
                    onClick={handleCheckScore}>
                    Check Score
                </button>
            </>}

            {
                (checkButtonPressed) ? <></> :
                    <>
                        <p className={'pt-1 -ml-2 text-xs text-slate-500'}>*Press button- to know your score</p>
                    </>
            }
        </div>
    );
};

export default Scoreboard;

