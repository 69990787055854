// BELOW CODE IS: IF YOU WANT VERY VERY SIMPLE, BASIC HORIZONTAL SCROLLING OF CARDS
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import HomeCard from './ProjectCard';
import {db, storage} from "../index";
import {collection, getDocs} from "firebase/firestore";
import useStore from "../store";
import CreateProject from "./CreateProject";

export default function HomePage() {

    const [projects, setProjects] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [clearSearch, setClearSearch] = useState(false);
    const showCreateProject = useStore(state => state.showCreateProject);
    const setShowCreateProject = useStore(state => state.setShowCreateProject);
    const [filter, setFilter] = useState("public");
    const user = useStore<any>(state => state.user);
    const userId = user?.multiFactor?.user?.uid;
    const isDarkMode = useStore(state => state.isDarkMode);
    const isLastProject = projects.length - 1;
    const [projectImg, setProjectImg] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0); /* initialize state for header height */

    useEffect(() => {
        const screenHeight = window.innerHeight; /* get screen height */
        const newHeaderHeight = screenHeight / 9; /* calculate header height as 1/3 of screen height */
        setHeaderHeight(newHeaderHeight); /* set state with header height value */
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        }
    }, []);

    useEffect(() => {
        const allProjects = getDocs(collection(db, "project_details")).then((projects) => {

            //@ts-ignore
            setProjects(projects.docs.map((project) => project.data()))

        });
    }, [])

    console.log("Projects array is this: ", projects)
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 1024) {
                setShowPopup(true);
            } else {
                setShowPopup(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let pvtProjectsCount = 0;


    //  below is search function to find project by name or description
    const filteredProjects = projects.filter((project) => {
        const projectName = project.project_name || '';
        const projectDescription = project.project_descp || '';
        const searchTermLowerCase = searchTerm?.toLowerCase() || '';
        return projectName.toLowerCase().includes(searchTermLowerCase) || projectDescription.toLowerCase().includes(searchTermLowerCase);
    });
    ;


    // @ts-ignore
    return (

        <div>

            {/*{isMobile && (*/}
            {/*    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-50 flex justify-center items-center">*/}
            {/*        <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">*/}
            {/*            <h2 className="text-xl font-bold mb-4">Website is not optimized for mobile</h2>*/}
            {/*            <p className="mb-4">We are, sorry but This website is not optimized for mobile devices- currently. Please visit us on a laptop or desktop</p>*/}
            {/*            /!*<button className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg" onClick={() => setIsMobile(false)}>Close</button>*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div
                className={`bodyContent flex flex-col justify-center overflow-x-hidden items-center pb-10 ${(isDarkMode) ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>


                {(!user ? (<div className=" overflow-x-hidden bodyContent flex flex-col justify-center pb-10"
                                style={{marginTop: `${headerHeight}px`}}>
                        <div className="welcomeText flex flex-col font-normal text-3xl justify-center items-center">Welcome
                            to
                        </div>
                        <div className="flex flex-col justify-center">
                            <img src={(isDarkMode) ? './images/whiteLogo.png' : './images/logo.png'}
                                 className="w-8/12 mt-4 items-center block m-auto mb-2"></img>
                        </div>
                        <div className="description block m-auto text-zinc-400">The only, collaboration tool you need</div>
                        <div className="description block m-auto text-zinc-400">to transform thoughts into products</div>
                    </div>) :

                    (<>
                        <div className="bodyContent flex flex-col justify-center pb-10">
                            <div className="flex justify-center">
                                <img src={(isDarkMode) ? './images/whiteLogo.png' : './images/logo.png'}
                                     className="w-9/12 items-center block m-auto mb-2"
                                     style={{marginTop: `${headerHeight}px`}}></img>
                            </div>
                            <div className="description block m-auto text-zinc-400">The only, collaboration tool you
                                need
                            </div>
                            <div className="description block m-auto text-zinc-400">to transform thoughts into
                                products
                            </div>
                        </div>
                    </>))}


                {/* Render popup for non-desktop/laptop users */}
                {showPopup && (
                    <div
                        className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-gray-700 bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 flex flex-col justify-center items-center">
                        <div className="bg-gray-900 border border-solid border-[#1CCAFF] rounded-lg p-8">
                            <p className="text-2xl font-medium mb-4 text-gray-300">Sorry, this website is not optimized
                                for mobile devices.</p>
                            <p className="text-lg text-gray-300">Please visit us from a desktop or laptop computer for
                                the best experience.</p>
                        </div>
                    </div>
                )}

                <div className={"flex flex-row font-normal mb-2 justify-center items-center"}>
                    <button
                        onClick={() => setFilter("public")}
                        // className={`border border-[#1CCAFF] rounded-lg p-2 hover:bg-[#1CCAFF] ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}>
                        className={`mr-3 border rounded-lg p-2 hover:bg-[#1CCAFF] ${
                            filter === "public"
                                ? `bg-[#1CCAFF] ${
                                    isDarkMode ? "text-white" : "text-slate-800"
                                }`
                                : `bg-transparent ${
                                    isDarkMode ? "text-white" : "text-slate-800"
                                }`
                        }`}>
                        <p>Public projects</p>
                    </button>
                    <button
                        onClick={() => setFilter("private")}
                        // className={`ml-3 border border-[#1CCAFF] rounded-lg p-2 hover:bg-[#1CCAFF] ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}
                        className={`border rounded-lg p-2 hover:bg-[#1CCAFF] ${
                            filter === "private"
                                ? `bg-[#1CCAFF] ${
                                    isDarkMode ? "text-white" : "text-slate-800"
                                }`
                                : `bg-transparent ${
                                    isDarkMode ? "text-white" : "text-slate-800"
                                }`
                        }`}>
                        <p>Private projects</p>
                    </button>

                    <button
                        // to={'/createProject'}
                        onClick={() => setShowCreateProject(true)}
                        className={`ml-3 border border-[#1CCAFF] rounded-lg p-2 hover:bg-[#1CCAFF] ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}>
                        <p className={`test-2xl ${(isDarkMode) ? "text-[#FAFBFA]" : ""}`}>
                            Create New Project</p>
                    </button>
                </div>

                {/*  below is search button to find project by its project name/ description*/}
                <div className={"flex flex-row font-normal mb-2 justify-center items-center"}>
                    <input
                        className={`border border-[#1CCAFF] w-96 rounded-lg p-2 hover:border-4 hover:border-[#1CCAFF] ${isDarkMode ? 'bg-slate-800 text-[#FAFBFA]' : 'text-slate-800'}`}
                        type="text"
                        placeholder="Search by project name or description"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearchResults([...filteredProjects])
                                setSearching(true)
                            }
                        }}
                    />
                    <button
                        onClick={() => {
                            setSearchResults([...filteredProjects])
                            setSearching(true)
                            console.log(filteredProjects, "is filtered projects 999")
                            console.log(searchResults, "is search results 999")
                        }}
                        className={`ml-3 border border-[#1CCAFF] rounded-lg p-2 hover:bg-[#1CCAFF] ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}>
                        <p>Search</p>
                    </button>
                    <button
                        onClick={() => {
                            setSearchResults([])
                            setSearching(false)
                        }}
                        className={`ml-3 border border-[#1CCAFF] rounded-lg p-2 hover:bg-[#1CCAFF] ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}>
                        <p>Clear Search</p>
                    </button>
                </div>


                {/* below is code for displaying public projects*/}
                {/*<p className={"flex font-normal text-2xl mt-22 justify-center items-center"}>Public Projects</p>*/}
                {(filter === "public" && projects) ?

                    (<div className={'w-11/12 overflow-y-hidden flex flex-row'} style={{height: "50rem"}}>
                        {/*hand gesture*/}
                        <img className={'w-20 h-20 mt-32 mr-20'}
                             src={(isDarkMode) ? `${window.location.origin}/images/scrollLeftWhite.png` : `${window.location.origin}/images/scrollLeft.png`}/>

                        <div className='mt-7 flex h-3/5 w-full overflow-y-hidden overflow-x-scroll whitespace-nowrap'>

                            {/*public projects rendering and list*/}
                            {searching ?
                                searchResults.filter((d: any) => !d.isPrivate).map((d: any, idx: number) => (
                                    <div className={`inline-block w-96 h-80 hover:ml-20 hover:mr-36 hover:border-[#1CCAFF] hover:z-50 ${idx === 0 ? 'ml-12' : '-ml-30'}`} key={idx}>
                                        <HomeCard defaultPageId={d.defaultTabId} projName={d.project_name}
                                                  projDescp={d.project_descp}
                                                  projIMG={(d.project_img === '' || d.project_img === undefined || d.project_img === null || !d.project_img) ? (isDarkMode) ? './images/WriteSpaceProjectSlate900.png' : './images/WriteSpaceProject.png' : d.project_img}
                                                  pvt={d.isPrivate}
                                                  id={d.id}/>
                                    </div>
                                ))
                                :
                                projects.filter((d: any) => !d.isPrivate).map((d: any, idx: number) => (
                                    <div className={`inline-block w-96 h-80 hover:ml-20 hover:mr-36 hover:border-[#1CCAFF] hover:z-50 ${idx === 0 ? 'ml-12' : '-ml-7'}`} key={idx}>
                                        <HomeCard defaultPageId={d.defaultTabId} projName={d.project_name}
                                                  projDescp={d.project_descp}
                                                  projIMG={(d.project_img === '' || d.project_img === undefined || d.project_img === null || !d.project_img) ? (isDarkMode) ? './images/WriteSpaceProjectSlate900.png' : './images/WriteSpaceProject.png' : d.project_img}
                                                  pvt={d.isPrivate}
                                                  id={d.id}/>
                                    </div>
                                ))
                            }
                        </div>

                        <img className={'w-20 h-20 mt-32 ml-20'}
                             src={(isDarkMode) ? `${window.location.origin}/images/scrollRightWhite.png` : `${window.location.origin}/images/scrollRight.png`}/>

                    </div>) : (null)}


                {/*private projects list and rendering*/}
                {
                    (userId && projects && filter === "private" ? (
                        <div className={'flex justify-center flex-row'}>
                            <img className={'w-20 h-20 mt-32 -mr-9'}
                                 src={(isDarkMode) ? `${window.location.origin}/images/scrollLeftWhite.png` : `${window.location.origin}/images/scrollLeft.png`}/>
                            <div
                                className={`overflow-x-scroll whitespace-nowrap ${isLastProject ? 'overflow-x-visible' : 'overflow-x-hidden'}`}>
                                <div className={`overflow-x-scroll whitespace-nowrap ml-24 mr-24`}>


                                    {/*add button*/}
                                    {/*<div*/}
                                    {/*  className="border rounded-lg border-[#000000] w-80 h-80 max-w-sm bg rounded-lg border border-black shadow-md m-5 w-80 h-80">*/}
                                    {/*  <Link className="addButton flex justify-center items-center w-80 h-80" to={user?"/createProject":"/"}>*/}
                                    {/*    <img src="./images/addButton.png" className="w-20 rounded-3xl"></img>*/}
                                    {/*  </Link>*/}
                                    {/*</div>*/}
                                    {projects.map(function (d: any, idx) {
                                        if (d.isPrivate && d.createdBy == user.multiFactor.user.uid) {
                                            pvtProjectsCount += 1
                                            return (
                                                <div className={`inline-block ${isLastProject ? 'w-80' : ''}`}>
                                                    <HomeCard key={idx} defaultPageId={d.defaultTabId}
                                                              projName={d.project_name} projDescp={d.project_descp}
                                                              projIMG={(d.project_img === '' || d.project_img === undefined || d.project_img === null || !d.project_img) ? (isDarkMode) ? './images/WriteSpaceProjectSlate900.png' : './images/WriteSpaceProject.png' : d.project_img}
                                                              pvt={d.isPrivate} id={d.id}/>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                            <div>
                                {pvtProjectsCount === 0 && (
                                    <div
                                        className="flex border rounded-lg border-slate-800 justify-center items-center w-72 h-80 -ml-40 mr-7 mt-4 mb-4">
                                        <p className="text-2xl">No Private Projects</p>
                                    </div>
                                )}
                            </div>

                            <img className={'w-20 h-20 mt-32 -ml-9'}
                                 src={(isDarkMode) ? `${window.location.origin}/images/scrollRightWhite.png` : `${window.location.origin}/images/scrollRight.png`}/>

                        </div>
                    ) : (<></>))}

                {showCreateProject &&
                    <div
                        className="fixed w-full border rounded-lg h-full top-0 left-0 flex items-center justify-center bg-slate-800 bg-opacity-80"
                        style={{zIndex: 90}}>
                        <div className="w-2/4 h-2/3 bg-[#FAFBFA] rounded-lg shadow-lg p-8 text-center mx-auto"
                             style={{zIndex: 999}}>
                            <CreateProject/>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
