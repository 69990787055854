import React, {useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import {db, storage} from "../index" //this is firebase storage that is database
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {uuidv4} from "@firebase/util";
import {useNavigate, useParams} from "react-router-dom";
import useStore from "../store";
import {Auth} from "../components/Auth";


const initValues = {
    projectName: "",
    projectDescription: "",
    privacy: false,
    image: "",
    id: "",
}

async function storeFirebase(inputValues: any, user: any, onSuccess: any) {
    const getFirebase = firebase.firestore();
    const newProjectId = uuidv4()
    const newTabId = uuidv4()
    let fireBaseUrl = ""

    if (inputValues.image !== "") {
        const uploadIMG = storage.ref(`/images/${newProjectId}`).put(inputValues.image)

        uploadIMG.on('state_changed',
            (snapShot) => {
                //takes a snapShot of the process as it is happening
                console.log("SnapShot of the process as img is uploading: ", snapShot)
            }, (err) => {
                //catches the errors
                console.log(err)
            }, async () => {
                // gets the functions from storage refences the image storage in firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                fireBaseUrl = await storage.ref('images').child(newProjectId).getDownloadURL()
                console.log("fireBaseUrl is: ", fireBaseUrl)
                console.log("Type of fireBaseUrl is: ", typeof fireBaseUrl)

                try {
                    await getFirebase.collection('project_details').add({
                        project_name: inputValues.projectName,
                        project_descp: inputValues.projectDescription,
                        project_img: fireBaseUrl,
                        isPrivate: inputValues.privacy,
                        id: newProjectId,
                        createdBy: user.multiFactor.user.uid,
                    });

                    onSuccess(newProjectId);
                } catch (err) {
                    console.log('Error in creating project is: ', err);
                }
            })
    } else {
        await getFirebase.collection("project_details").add({
            project_name: inputValues.projectName,
            project_descp: inputValues.projectDescription,
            project_img: '',
            isPrivate: inputValues.privacy,
            id: newProjectId,
            defaultTabId: newTabId, //TODO: find where is it used , if no where then delete
            createdBy: user.multiFactor.user.uid,
        }).catch((err) => {
            console.log("Error in creating project is: ", err);
        })

        onSuccess(newProjectId)
    }
}


// below is function to add data to the firebase
// async function storeFirebase(inputValues: any, user: any, onSuccess: any) {
//
//   const getFirebase = firebase.firestore();
//   const newProjectId = uuidv4()
//   const newTabId = uuidv4()
//   const uploadIMG = storage.ref(`/images/${newProjectId}`).put(inputValues.image)
//
//   uploadIMG.on('state_changed',
//     (snapShot) => {
//       //takes a snapShot of the process as it is happening
//       console.log("SnapShot of the process as img is uploading: ",snapShot)
//     }, (err) => {
//       //catches the errors
//       console.log(err)
//     }, async () => {
//       // gets the functions from storage refences the image storage in firebase by the children
//       // gets the download url then sets the image from firebase as the value for the imgUrl key:
//       const fireBaseUrl = await storage.ref('images').child(newProjectId).getDownloadURL()
//
//
//         //below code is to add project to the firebase
//       await getFirebase.collection("project_details").add({
//         project_name: inputValues.projectName,
//         project_descp: inputValues.projectDescription,
//         project_img: fireBaseUrl,
//         isPrivate: inputValues.privacy,
//         id: newProjectId,
//         defaultTabId: newTabId, //TODO: find where is it used , if no where then delete
//         createdBy: user.multiFactor.user.uid,
//       })
//
//
//         //below code is to add tabs to the project to the firebase
//       // await getFirebase.collection("project_tabs").add({
//       //   project_id: newProjectId,
//       //   name: DEFAULT_TAB_NAME,
//       //   pageID: newTabId,
//       //   created: Math.round((new Date()).getTime() / 1000)
//       // })
//       onSuccess(newProjectId)
//     })
//
// }

export default function CreateProject() {

    // const setActiveTab = useStore(state => state.setActiveTab);
    const user = useStore(state => state.user);
    const [showAuthPopUp, setShowAuthPopUp] = useState(false);
    const setSignInisBig = useStore((state) => state.setSignInisBig);
    const [privacy, setPrivacy] = useState(false);
    const [creatingProject, setCreatingProject] = useState(false);
    //this is to set the values of the form - title, discription, privacy
    const [values, setValues] = useState(initValues);
    const setShowCreateProject = useStore((state) => state.setShowCreateProject);

    console.log('user value is: ', user)

    const handleInputChange = (e: any) => {
        e.preventDefault();
        console.log(e)
        const {name, value} = e.target; //this line represents variable destruction just like we have object destruction

        setValues({
            ...values,
            [name]: value,
        });
    };


    //below function changes image
    const changeImage = (event: any) => {
        const file = event.target.files[0]
        event.preventDefault();

        if (file) {
            setValues({
                ...values,
                ['image']: file,
            });
            console.log("Image file is: ", file);
        } else {
            console.log("No image file is uploaded by user");
        }
    }

    const clearForm = () => {
        setValues(initValues)
    }

    let navigate = useNavigate();


    const onSubmit = async (e: any) => {
        e.preventDefault();
        console.log("creatingProject value onSubmit button press is: ", creatingProject);


        if (user) {
            console.log("creatingProject value onSubmit button press is: ", creatingProject);
            console.log("Project data values are: ", values);
            console.log("User details are: ", user);

            if (values.projectName.trim() === '') {
                toast.error('Project name cannot be empty', {
                    position: "top-center",
                    style: {
                        color: "black",
                    }
                });
                return;
            }

            if (values.projectDescription.trim() === '') {
                toast.error('Project description cannot be empty', {
                    position: "top-center",
                    style: {
                        color: "black",
                    }
                });
                return;
            }

            setCreatingProject(true)
            await storeFirebase(values, user, (projectId) => {

                setTimeout(() => {
                    console.log("onSuccess project function executed: ");
                    setCreatingProject(false)
                    setCreatingProject(false)
                    navigate(`/projects/${projectId}/`)
                }, 400)


                //TODO PROUD OF THIS
                // setActiveTab(pageId)
                clearForm()
            }).catch((err) => {
                console.log("Error in creating project is: ", err);
            })

        } else {
            setShowAuthPopUp(true)
            setSignInisBig(true)
        }

    }

    function privacyChange() {
        setPrivacy(!privacy)
        setValues({
            ...values,
            ['privacy']: !privacy,
        })
    }

    // @ts-ignore
    return (
        <>
            <div className='allParts flex flex-col bg-[#FAFBFA]'>

                {showAuthPopUp && !user ? (<Auth action={() => {
                    setShowAuthPopUp(false)
                }}/>) : null}


                {creatingProject ? (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                            <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-20"></div>
                            <div className="bg-white rounded-lg shadow-lg p-8 max-w-xs max-h-full text-center mx-auto">
                                <div className="flex justify-center mb-4">
                                    <div
                                        className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#1CCAFF]"
                                        style={{borderColor: '#1CCAFF'}}></div>
                                </div>
                                <p className="text-gray-900 font-medium mb-2">Loading...</p>
                                <p className="text-gray-600 text-sm">Please wait while we create your project.</p>
                            </div>
                        </div>
                    ) :
                    (
                        <div className={'flex flex-col bg-[#FAFBFA]'}>

                            <div className={'flex w-full items-right justify-end -mt-3 -mr-12'}>
                                <button onClick={()=>setShowCreateProject(false)}>
                                    <img src={`${window.location.origin}/images/cross.png`} alt="close icon" className={'w-7 h-7'}/>
                                </button>
                            </div>


                            <form onSubmit={onSubmit}
                                  className={''}>

                                {/**/}
                                <div className="projectName flex flex-col items-left justify-left">
                                    <div className={'w-full flex justify-left items-left pb-1'}>
                                        <p>Project Name</p>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            className='bg-[#FAFBFA] border border-[#000000] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
                                            value={values.projectName}
                                            placeholder={'Enter project name'}
                                            onChange={handleInputChange}
                                            name="projectName" //important
                                        />
                                    </div>
                                </div>


                                {/*project description input div element*/}
                                <div className="projectDescript flex flex-col items-left justify-left mt-7">
                                    <div className="w-full flex justify-left items-left pb-1">
                                        <p>
                                            Project Description
                                        </p>
                                    </div>
                                    <div className="descInput w-full">
                                        <input
                                            className='bg-[#FAFBFA] border border-[#000000] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
                                            value={values.projectDescription}
                                            onChange={handleInputChange}
                                            placeholder={'Enter project description'}
                                            name="projectDescription" //important
                                        />
                                    </div>
                                </div>


                                {/*project image upload div element*/}
                                <label className="flex justify-left items-left mb-1 mt-5 text-sm font-medium" htmlFor="file_input">
                                    Upload image for your project
                                </label>
                                <input name="image"
                                       className="block mb-9 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer"
                                       id="file_input" type="file"
                                       onChange={changeImage}/>


                                {/*project privacy div element*/}
                                <div className={'w-full flex flex-col items-left justify-left -mt-2'}>
                                    <label className="flex justify-left items-left text-sm font-medium pb-1" htmlFor="file_input">
                                        Choose visibility of your project, if you want it to be private or public
                                    </label>
                                    <label className={'inline-flex relative items-left cursor-pointer'}>
                                        <input type="checkbox" name={"privacy"} onChange={privacyChange}
                                               className={"sr-only peer"}/>
                                        <div
                                            className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#1CCAFF] rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-black peer-checked:after:bg-[#1CCAFF] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                                        {privacy ? (
                                            <span className={"ml-3 text-sm font-medium text-black"}>Private</span>) : (
                                            <span className="ml-3 text-sm font-medium text-[#18181b]">Public</span>)}
                                    </label>
                                </div>


                                {/*submit button div element*/}
                                <div>
                                    <input type="submit" value={'submit'}
                                           className='bg-[#1CCAFF] mt-9 items-center font-normal text-[#18181b] font-bold py-2 px-4 rounded-xl hover:bg-[#000000] hover:text-[#FFFFFF]'/>
                                    <ToastContainer/>
                                </div>


                            </form>

                        </div>)}
            </div>
        </>
    )

}
