import React, {useEffect, useState} from "react";
import {collection, query, where, getDocs, updateDoc, doc} from "firebase/firestore";
import {db, storage} from "../index";
import useStore from "../store";
import {useNavigate, useParams} from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {getFirestore} from "firebase/firestore";
import {useRef} from "react";
import {getProject} from "../services/project.service";
import {uuidv4} from "@firebase/util";
import {DEFAULT_TAB_NAME} from "../constants";
import {ToastContainer} from "react-toastify";
import {any, object} from "prop-types";
import {isPrivate} from "@babel/types";


async function updateFirebase(updateValues: any, projectId: string, user: any, onSuccess: any) {


  // @ts-ignore
  const getFireDatabase = firebase.firestore();


  const uploadIMG = storage.ref(`/images/${updateValues.project_name}`).put(updateValues.image)

  uploadIMG.on('state_changed',
    (snapShot) => {
      //takes a snapShot of the process as it is happening
      console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, async () => {

    console.log(updateValues)

      const project = await getProject(projectId)

      console.log("Returned project: " + project)

      // project.ref.update({
      //   project_name: updateValues.projectName,
      //   project_descp: updateValues.projectDescription,
      //   project_img: updateValues.image,
      //   isPrivate: updateValues.privacy,
      // })

      console.log("Updated values: ", updateValues)

      const fireBaseUrl = await storage.ref('images').child(updateValues.project_name).getDownloadURL()

      await updateDoc(project.ref, {
        project_name: updateValues.project_name,
        project_descp: updateValues.project_descp,
        project_img: fireBaseUrl,
        isPrivate: updateValues.isPrivate,
      })

      onSuccess()

    })

}


export default function Setting() {



  const user = useStore(state => state.user)
  const [projectData, setProjectData] = useState<any>([])
  const params = useParams();
  const projectID = params.projectId
  const tabId = params.tabId


  useEffect(() => {
    console.log("projectID", projectID)
    getProject(projectID).then((data: any) => {
        setProjectData(data.data())
      }
    )
  }, [projectID])


  const handleInputChange = (e: any) => {
    console.log(e)
    const {name, value} = e.target; //this line represents variable destruction just like we have object destruction

    setProjectData({
      ...projectData,
      [name]: value,
    });
  };



  function privacyChange() {

    setProjectData({
      ...projectData,
      ['isPrivate']: !projectData.isPrivate,
    });
  }

  let navigate = useNavigate();
  const onSave = (e: any) => {
    e.preventDefault(); //important html form concept

      updateFirebase(projectData, projectID, user, () => {

        navigate(`/projects/${projectID}/page/${tabId}`)

      }).then(r => {})
    }

  const changeImage = (event: any) => {
    const file = event.target.files[0]

    setProjectData({
      ...projectData,
      ['image']: file,
    });
  }


  return (
    <form onSubmit={onSave}>
          <div className={"flex ml-120 mr-120 flex-col"}>

            <h1 className={"mt-24 text-5xl"}>Setting</h1>

            {/*privacy setting*/}
            <div className={"flex justify-between mt-12 flex-row"}>
                <h1 className={" text-l"}>Privacy Setting</h1>
                <label className={'inline-flex relative items-center cursor-pointer'}>
                      <input type="checkbox" name={"isPrivate"} value={projectData.isPrivate} onChange={privacyChange} className={"sr-only peer"}/>
                      <div
                        className={"w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#b6e5b0] rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-black peer-checked:after:bg-[#b6e5b0] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"}></div>
                      {projectData.isPrivate ? (<span className={"ml-3 text-sm font-medium text-black"}>Private</span>) : (
                        <span className="ml-3 text-sm font-medium text-[#18181b]">Public</span>)}
                </label>
            </div>

            {/*project name*/}
            <div className={"flex justify-between mt-12 flex-col"}>
              <h1 className={"mb-2 text-l"}>Project Name</h1>
              <input type="text" className={"w-96 h-12 border-2 border-gray-300 rounded-md"} name={'project_name'} placeholder={projectData.project_name} value={projectData.project_name} onChange={handleInputChange}/>
            </div>

            <div className={"flex justify-between mt-12 flex-col"}>
              <h1 className={"mb-2 text-l"}>Project Description</h1>
              <input type="text" className={"w-96 h-12 border-2 border-gray-300 rounded-md"} name={'project_descp'} placeholder={projectData.project_descp} value={projectData.project_descp} onChange={handleInputChange}/>
            </div>


            <label className="block mb-2 mt-9 text-sm font-medium" htmlFor="file_input">
              Upload image for your project
            </label>
            <input name="image"
                   className="block mb-9 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer"
                   id="file_input" type="file"
                   onChange={changeImage}/>


            <div>
              <input type="submit" value={'SAVE'}
                     className='bg-[#b6e5b0] mt-9 items-center font-normal text-[#18181b] font-bold py-2 px-4 rounded-xl hover:bg-[#000000] hover:text-[#FFFFFF]'/>
              <ToastContainer/>
            </div>

          </div>
    </form>
  );

}