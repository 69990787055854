//open ai key is: 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

//this file is completely chatgpt chatbot

// @ts-ignore


import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {getChatGPTMessages, updateMessages} from "../services/project.service";
import useStore from "../store";


function ChatGPT() {

    const params = useParams();
    const projectId = params.projectId
    const isDarkMode = useStore(state => state.isDarkMode);
    const [chatInput, setChatInput] = useState<string>("");
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [inputFieldData, setInputFieldData] = useState<any>(null);
    const [messages, setMessages] = useState([
        {
            message: "Wassup buddy? I am your new friend- Kavun. I am here for you. Ask me whatever is in your mind ;) My outfit is sexy, isn't it?",
            sender: "OpenAI",
            direction: "incoming",
        },
    ]);

    const [gptMsgFormat, setGptMsgFormat] = useState([
        {
            role: "system",
            content: "You are a helpful friendly assistant who can help with anything"
        },
    ]);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        // scroll to the bottom of the message list when a new message is added
        if (messagesEndRef !== null) {
            messagesEndRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [messages]);

    useEffect(() => {

        const loadMessages = async () => {
            const oldMessages = await getChatGPTMessages(projectId, messages);
            console.log("Loaded list of messages are: ", oldMessages.get("messages"));
            setMessages([...oldMessages.get("messages")]);
            console.log("Messages value after setMessages: ", messages);
        }

        loadMessages().then(r => console.log("LoadMessages function run in useEffect: ", r));
    }, []);


    const {Configuration, OpenAIApi} = require('openai');

    const configuration = new Configuration({
        apiKey: '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',
    });

    const openai = new OpenAIApi(configuration);

    async function getCompletionFromOpenAI(updatedGPTMessagesList) {
        const completion = await openai.createChatCompletion({
            // model: "gpt-3.5-turbo",
            model: "gpt-4",
            // prompt: "The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly. He replies very cool like a best friend and knows answers to everything and anything",
            messages: [...updatedGPTMessagesList],
            temperature: 0,
        });

        console.log("Response from Chatgpt is :", completion.data.choices[0].message.content);

        return completion.data.choices[0].message.content;
    }


    const handleSend = async (message) => {

        const userInput = {
            message: chatInput,
            sender: "user",
            direction: "outgoing",
        }

        setInputFieldData({
                type: "submit", // when user clicks the send icon on chatGPT  input field.
                method: "chat-gpt",
                length_characters: chatInput.length.toString(),
                logged_in: "yes",
                paid_user: "idk",
            }
        )

        console.log('event: "input_field", data: ', inputFieldData)

        const updatedMessagesList = [...messages, userInput]
        const updatedGPTMessagesList = [...gptMsgFormat, {role: "user", content: chatInput}]

        await setMessages(updatedMessagesList)
        await setGptMsgFormat(updatedGPTMessagesList)

        // const shortMessagesList = [
        //     {role: updatedMessagesList[0].sender === "user" ? "user" : "assistant",
        //         content: updatedMessagesList[0].message},
        //     {role: updatedMessagesList[updatedMessagesList.length - 2].sender === "user" ? "user" : "assistant",
        //         content: updatedMessagesList[updatedMessagesList.length - 2].message},
        //     {role: updatedMessagesList[updatedMessagesList.length - 1].sender === "user" ? "user" : "assistant",
        //         content: updatedMessagesList[updatedMessagesList.length - 1].message}
        // ]
        // console.log('Short messages list is: ', shortMessagesList)

        //set typing indicator to true
        setIsTyping(true)

        //process message to ChatGPT
        await getCompletionFromOpenAI(updatedGPTMessagesList).then((response) => {

            console.log("Response from Chatgpt is :", response)

            const gptResponse = {
                message: response,
                sender: "OpenAI",
                direction: "incoming",
            }
            const userMessage = {
                message: chatInput,
                sender: "user",
                direction: "outgoing",
            }

            const updatedMessagesList = [...messages, userMessage, gptResponse]
            const updatedGPTMessagesList = [...gptMsgFormat, {role: "user", content: chatInput}, {
                role: "assistant",
                content: response
            }]

            setMessages(updatedMessagesList)
            setGptMsgFormat(updatedGPTMessagesList)
            console.log("The 'updated' GPT message array after updating and adding user input message \n : ", updatedGPTMessagesList)
            console.log("The 'updated' message array after updating and adding user input message \n : ", updatedMessagesList)

            //update the messages in the database
            updateMessages(projectId, updatedMessagesList)

        })

        //set typing indicator to false
        setIsTyping(false)


    }


    const apiKey = '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'

    const gptMessage = (message) => {
        return (
            <div className="chat-message">
                <div className="flex items-end justify-start">
                    <div className="flex flex-col w-80 space-y-2 text-xs max-w-xs mx-2 order-1 items-start">
                        <div className={'pt-4 pb-4'}>
                            <div className={"flex items-end justify-start"}>
                                <text className={`mr-2`}>{message.sender}</text>
                            </div>
                            <span className={`px-4 py-2 rounded-lg inline-block rounded-bl-none font-inter ${isDarkMode ? 'bg-[#FAFBFA] text-gray-700' : 'bg-slate-700 text-gray-300'}`}>{message.message}</span>
                            {/*<span*/}
                            {/*    className="px-4 py-2 rounded-lg inline-block rounded-bl-none font-inter bg-gray-700 text-gray-300">{message.message}</span>*/}
                        </div>
                    </div>
                </div>
            </div>)
    }

    const userMessage = (message) => {
        return (
            <div className="chat-message">
                <div className="flex items-end justify-end">
                    <div className="flex flex-col w-7/12 space-y-2 text-xs order-1 items-end">
                        <div className={'pt-1 pr-1'}>
                            <span
                                className="px-4 py-2 rounded-lg inline-block font-inter rounded-br-none bg-[#1CCAFF] text-black ">{message.message}</span>
                        </div>
                    </div>
                </div>
            </div>)
    }


    return (

        <div className={`h-screen overflow-y-scroll justify-end align-end z-40 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>

            <div className={`topBar fixed w-3.5/12 pb-5 fixed pl-16 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>
                <div className={'flex flex-col'}>
                    <div className={"aiAssistantTitle pt-5 ml-5 -mono text-2xl " + (isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800')}>
                        <p>Kavun AI Assistant</p>
                    </div>
                    <div className="relative group -right-60 -top-7">
                        <img src={(isDarkMode) ? `${window.location.origin}/images/whiteInfoButton.png` : `${window.location.origin}/images/infoButton.png`} className="w-6 h-6 group-hover:block" alt="Tooltip icon"/>
                        <div className={`absolute -ml-78 hidden group-hover:block ${isDarkMode ? 'bg-gray-700 text-gray-100 border border-[#1CCAFF]' : 'bg-slate-800 text-[#FAFBFA]'} px-4 py-3 rounded rounded-lg rounded-tr-none shadow-lg`}>
                            <p className="w-72 text-xs">Hey! I am Kavun- here to help you and answer all your questions</p>
                        </div>
                    </div>

                    <p className={isDarkMode? "text-xs -mt-5 ml-24 mb-7 italic text-[#FAFBFA]": " text-xs -mt-5 ml-24 mb-7 italic text-slate-800"}>(ChatGPT 4)</p>
                </div>





                <div className={"aiAvatarImage"}>
                    <img src={(isDarkMode) ? `${window.location.origin}/images/whiteWizard.png` : `${window.location.origin}/images/wizard.png`} className={'-mt-4 ml-24 w-20 h-16'}/>
                </div>
            </div>


            <div className={`MessageList ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'} w-auto max-w-sm pl-5 pt-2 mt-36 mb-24`}>
                {
                    messages.map((message) => {
                        if (message.sender === "OpenAI") {
                            return gptMessage(message)
                        } else {
                            return userMessage(message)
                        }
                    })
                }
                <div ref={messagesEndRef}/>
            </div>


            {/*typing indicator is below*/
            }
            {
                isTyping ? (
                    <div className={"typing-indicator fixed bottom-12"}>
                        <div className="flex flex-row items-center space-x-2">
                            <span className={`gptTypingText text-sm ${isDarkMode ? 'text-[#FAFBFA]' : 'text-slate-800'}`}>Kavun is typing</span>
                            <img src={'/images/loadingDots.gif'} width={50}/>
                        </div>
                    </div>
                ) : null
            }


            {/*below is Chat box and send button*/
            }
            <form className={''}>
                <label htmlFor="chat" className="sr-only">Your message</label>
                <div className={isDarkMode ? 'fixed ml-0.2 bg-slate-800 bottom-0 border border-1 border-slate-400 h-14 ' : 'fixed ml-0.2 bg-[#FAFBFA] bottom-0 border border-1 border-slate-400 h-14 w-4/12'}>
                    <textarea id="output" rows={1}
                              className={`fixed z-50 p-2.5 bottom-2 left-31 w-2.5/12 text-sm rounded-lg border border-gray-300 focus:ring-blue-500 ${isDarkMode ? 'bg-[#FAFBFA] text-gray-800' : 'bg-slate-800 text-[#FAFBFA]'}`}
                              placeholder="Your message..."
                              value={chatInput}
                              onChange={(e) => {
                                  setChatInput(e.target.value)
                                  console.log(chatInput)
                              }}
                              onKeyDown={(e) => {
                                  if (e.key === "Enter" && !e.shiftKey) {
                                      e.preventDefault();
                                      if (chatInput !== "") {
                                          handleSend(chatInput).then(r => console.log("After send button is pressed: ", r));
                                          setChatInput(""); // clear the input after sending
                                      }
                                      setChatInput('')
                                  }
                              }}
                    ></textarea>
                    <button type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                if (chatInput !== "") {
                                    handleSend(chatInput).then(r => console.log("After send button is pressed: ", r));
                                    setChatInput("");
                                }
                            }
                            }
                            className="fixed z-50 bottom-4 left-130 text-blue-600 rounded-full cursor-pointer">
                        <svg aria-hidden="true" color={'#1CCAFF'} className="w-6 h-6 rotate-90"
                             fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                        <span className="sr-only">Send message</span>
                    </button>
                </div>
            </form>

        </div>
    )
        ;
}

export default ChatGPT;
