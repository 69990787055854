import create from "zustand";
import {createClient, LiveList, LiveObject} from "@liveblocks/client";
import { liveblocks } from "@liveblocks/zustand";
import React from "react";

import { mountStoreDevtool } from 'simple-zustand-devtools';

import {
    ADMIN_CHEAT_CODE,
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  LIVEBLOCKS_PUBLIC_KEY,
  RANDOM_COMMENT_COLOUR,
  RANDOM_POST_COLOURS, ZOOM_MAX, ZOOM_MIN
} from "./constants";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {BlockType, CustomElement} from "./types";
import {nanoid} from "nanoid";
import {useParams} from "react-router-dom";
import {createRoomContext} from "@liveblocks/react";


const client = createClient({
  publicApiKey: LIVEBLOCKS_PUBLIC_KEY,
});

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function getRandomColor() {
  return RANDOM_POST_COLOURS[getRandomInt(RANDOM_POST_COLOURS.length)];
}

export function getRandomColorForComment(comment: string) {
  const hash = hashStr(comment);
  const index = hash % RANDOM_COMMENT_COLOUR.length;
  return RANDOM_COMMENT_COLOUR[index];
}

//very simple hash
function hashStr(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash += charCode;
  }
  return hash;
}

export type Shape = { x: number; y: number; fill: string; title?: string; content?: string, comments?: string[] ,votecount?: number};

type Store = {
  activeTab: string;
  isAdmin: boolean;
  isDarkMode: boolean;
  checkAdmin: () => void;
  signInisBig: boolean;
  shapes: LiveObject<{ [id: string]: Shape }>;
  blocks: CustomElement[];
  cursor: { x: number; y: number } | null;
  message: string;
  panzoom: any;
  setPanzoom: (panzoom: any) => void;
  showCreateProject: boolean;
  setShowCreateProject: (showCreateProject: boolean) => void;
  selectedShape: string | null;
  selectedBlockId: string | null;
  isDragging: boolean;
  insertRectangle: () => void;
  createPost: (title: string, content: string) => void;
  onShapePointerDown: (shapeId: string) => void;
  deleteShape: (id: any) => void;
  onCanvasPointerUp: (id: any) => void;
  onCanvasPointerMove: (e: React.PointerEvent) => void;
  user: any;
  setUser: (user: any) => void;
  setDarkMode: (isDarkMode: boolean) => void;
  initUser: () => void;
  activePost: string | null;
  setSignInisBig: (isBig: boolean) => void;
  setActivePost: (post: any) => void;
  createComment: (postId: string, comment: string) => void;
  doUpvote: (postId: string, votecount: number) => void;
  getUpvoteCount: (postId: string) => number;
  onCanvasPointerMoveManual: (id: string, x: number, y: number) => void;
  zoomIn: (difference: number) => void;
  zoomOut: (difference: number) => void;
  setZoom: (zoom: number) => void;
  setActiveTab: (tab: string) => void;
  deleteComment: (post: any, commentId: number) => void;
  resetZoom: () => void;
  goToCenter: () => void;
  updateSelectedBlockId: (id: string | null) => void;
  updateBlocks: (blocks: CustomElement[]) => void;
  setShapes: (shapes: LiveObject<{ [id: string]: Shape }>) => void;
};

type Presence = {
  selectedShape: string | null;
  cursor: {
    x: number;
    y: number;
  } | null;
  message: string;
  selectedBlockId: string | null;
};

export const blockInitialValue: CustomElement[] = [
    {
        id: nanoid(),
        type: BlockType.Title,
        children: [
            {
                text: "Start writing here",
            },
        ],
    }
]

const useStore = create<Store>(
    (set, get) => ({
      shapes: null,
        isDarkMode: false,
      signInisBig: false,
      activeTab: "",
      blocks: blockInitialValue,
      cursor: null,
      message: "",
      selectedShape: null,
      selectedBlockId: null,
      isDragging: false,
      user: null,
      activePost: null,
      panzoom: null,
      isAdmin: false,
        showCreateProject: false,
      setShapes: (shapes) => set({ shapes }),
      deleteComment: (postId: any, commentId: number) => {
        const shapes = get().shapes;
        const comments = shapes.toObject()[postId].comments;

        // @ts-ignore
        const newComments = [...comments.slice(0, commentId), ...comments.slice(commentId + 1)]

        if (postId && shapes.toObject()[postId]) {
          const newShapes = {
            ...shapes.toObject(),
            [postId!]: {
              ...shapes[postId],
              // @ts-ignore
              comments: [...newComments]
            }
          }
          shapes.update(newShapes);
          // set({
          //   shapes: ,
          // })
        }
      },
      setSignInisBig: (isBig: boolean) => {
        set({signInisBig: isBig});
      },
      checkAdmin: () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          // @ts-ignore
          get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.values;
        // @ts-ignore
        if (value === ADMIN_CHEAT_CODE) {
          set({ isAdmin: true });
        }
      },
      setActivePost: (post: any) => {
        set({ activePost: post, isDragging: false, selectedShape: null });
      },
      setActiveTab: (tab: string) => {
        set({ activeTab: tab });
      },
      setPanzoom: (panzoom: any) => {
        set({ panzoom });

      },
        setDarkMode: (isDarkMode: boolean) => {
            set({ isDarkMode });
        },
      zoomIn: (difference: number) => {
        const { panzoom } = get();
        panzoom.current.zoomIn(difference);
      },
      zoomOut: (difference: number) => {
        const { panzoom } = get();
        panzoom.current.zoomOut(difference);
      },
      doUpvote: (postId: string) => {
        const { shapes } = get();

        const post = shapes.toObject()[postId!];
        console.log(shapes.toObject(), postId, post);
        // shapes mean post and post var here means ID of the post

        const count = (post.votecount || 0) + 1;
        if (postId && post) {
          const newShapes = {
            ...shapes.toObject(),
            [postId!]: {
              ...post,
              votecount: count
            },
          }
          shapes.update(newShapes);
          // set({
          //   shapes: {
          //     ...shapes,
          //     [postId!]: {
          //       ...post,
          //       votecount: count
          //     },
          //   },
          // });
        }

      },

      getUpvoteCount(postId: string) {
        const { shapes } = get(); //typescript error
        const post = shapes.toObject()[postId!];
        return post.votecount || 0;
      },

      setZoom: (zoom: number) => {
        const { panzoom } = get();
        panzoom.current.setZoom(zoom);
      },
      resetZoom: () => {
        const { panzoom } = get();
        panzoom.current.reset();
      },
      goToCenter: () => {
        const { panzoom } = get();
        if (panzoom && panzoom.current) {
          console.log("go to center");
          panzoom.current.setPosition((CANVAS_WIDTH / -2) , (CANVAS_HEIGHT / -2) );
          panzoom.current.setZoom(ZOOM_MAX);
        } else {
          const interval = setInterval(() => {
            const { panzoom } = get();
            // console.log("go to center retry", panzoom, panzoom.current);
            if (panzoom && panzoom.current) {
              console.log("go to center from loop");
              panzoom.current.setPosition((CANVAS_WIDTH / -2) , (CANVAS_HEIGHT / -2) );
              panzoom.current.setZoom(ZOOM_MAX);
              clearInterval(interval);
            }
          }, 100)
        }
      },
      createComment: (postId: string, comment: string) => {
        const { shapes } = get();

        const post = shapes.toObject()[postId!];

        if (postId && post) {
          const newShapes = {
            ...shapes.toObject(),
            [postId!]: {
              ...post,
              comments: [...post.comments! || [], comment]
            },
          }
          shapes.update(newShapes);
          // set({
          //   shapes: {
          //     ...shapes,
          //     [postId!]: {
          //       ...post,
          //       comments: [...post.comments! || [], comment]
          //     },
          //   },
          // });
        }


      },
      insertRectangle: () => {
        const { shapes } = get();

        const shapeId = Date.now().toString();
        const shape = {
          x: CANVAS_WIDTH,
          y: CANVAS_HEIGHT,
          fill: getRandomColor(),
        };

        //TODO
        // liveblocks.room!.updatePresence(
        //   { selectedShape: shapeId },
        //   { addToHistory: true }
        // );
        if (shapeId) {
          const newShapes = { ...shapes.toObject(), [shapeId]: shape };
          shapes.update(newShapes);
          // set({
          //   shapes: { ...shapes, [shapeId]: shape },
          // });
        }
      },
      setUser: (user) => {
        set({ user });
      },
      initUser: () => {
        const { goToCenter } = get()
        set({ user: firebase.auth().currentUser });
        goToCenter();
      },
        setShowCreateProject: (showCreateProject: boolean) => {
            set({ showCreateProject });
        },
      createPost: (title, content) => {
        console.log("create post !!!");
        const { shapes, panzoom } = get();

        const shapeId = Date.now().toString();
        const shape = {
          x: CANVAS_WIDTH / 2  + 400,
          y: CANVAS_HEIGHT / 2  + 200,
          fill: getRandomColor(),
          title: title,
          content: content,
        };

        if (shapeId) {
          const newShapes = { ...shapes.toObject(), [shapeId]: shape }
          console.log("newShapes", newShapes);
          shapes.update(newShapes);
          // set({
          //   shapes: { ...shapes, [shapeId]: shape },
          // });
          panzoom.current.setPosition(shape.x * -1 + 400, shape.y * -1 + 200);
          panzoom.current.setZoom(1);
          //TODO
          // liveblocks.room!.updatePresence(
          //   { selectedShape: shapeId, cursor: {

          //       x: shape.x * -1,
          //       y: shape.y * -1,
          //     } },
          //   { addToHistory: true }
          // );
        }
      },
      onShapePointerDown: (shapeId) => {
        console.log("NANACHI")

        //TODO
        // const room = get().liveblocks.room!;
        // room.history.pause();
        // room.updatePresence({ selectedShape: shapeId,  }, { addToHistory: true });
        set({ isDragging: true, selectedShape: shapeId });
      },
      deleteShape: (id: any) => {
        const { shapes } = get();
        const { [id!]: shapeToDelete, ...newShapes } = shapes.toObject();
        //TODO
        // liveblocks.room!.updatePresence(
        //   { selectedShape: null },
        //   { addToHistory: true }
        // );
        shapes.update(newShapes);
        // set({
        //   shapes: newShapes,
        // });
      },
      onCanvasPointerUp: (shapeId) => {
        const { shapes, panzoom } = get();
        set({ isDragging: false, selectedShape: null });
        // get().liveblocks.room!.history.resume();

        // panzoom.current.setPosition((shapes[shapeId].x / -1) , (shapes[shapeId].y / -1) );
        // panzoom.current.setZoom(ZOOM_MAX);
      },
      onCanvasPointerMove: (e) => {
        e.preventDefault();
        console.log("onCanvasPointerMove", e);

        const { isDragging, shapes, selectedShape } = get();
        const shape = shapes[selectedShape!];

        if (shape && (Math.abs(shapes.toObject()[selectedShape!]?.x - e.clientX) > 1) || Math.abs(shapes.toObject()[selectedShape!]?.y - e.clientY) > 1) {
          console.log("move", e.clientX, e.clientY);
          const newShapes = {
            ...shapes.toObject(),
            [selectedShape!]: {
              ...shape,
              x: e.clientX - 50,
              y: e.clientY - 50,
            },
          }

          shapes.update(newShapes);

          // set({
          //   shapes: {
          //     ...shapes,
          //     [selectedShape!]: {
          //       ...shape,
          //       x: e.clientX - 50,
          //       y: e.clientY - 50,
          //     },
          //   },
          // });
        }
      },
      onCanvasPointerMoveManual: (id, x, y) => {
        console.log("move manual", id, x, y);
        const { shapes } = get();
        const shape = shapes.toObject()[id!];

        if (id && shape) {
          const newShapes = {
            ...shapes.toObject(),
            [id!]: {
              ...shape,
              x: x,
              y: y,
            },
          }
          shapes.update(newShapes);
          // set({
          //   shapes: {
          //     ...shapes,
          //     [id!]: {
          //       ...shape,
          //       x: x,
          //       y: y,
          //     },
          //   },
          // });
        }
      },
      updateSelectedBlockId: (id) => {
        // const { liveblocks } = get();
        // liveblocks.room!.updatePresence(
        //   { selectedShape: id },
        //   { addToHistory: true }
        // );
      },
      updateBlocks: (NewBlocks) => {
        // const { liveblocks } = get();
        console.log("updateBlocks: ", NewBlocks)
        try {
          set({
            blocks: [
              ...NewBlocks
            ],
          })
        } catch (e) {
          console.log("error during saving to liveblock: ", e)
          // const room  = liveblocks.room;
          // liveblocks.leaveRoom(room!.id);
          // liveblocks.enterRoom(room!.id);
        }

        set({ blocks: [...NewBlocks] });
      },
    })
);

type ReactPresence = {
  // cursor: { x: number, y: number } | null,
  // ...
};

export type ReactStorage = {
  // animals: LiveList<string>,
  // ...
};

//@ts-ignore
export const {
  RoomProvider,
  useMyPresence,
  useObject,
  useRoom,
  useList,
  useUpdateMyPresence,
  useOthers,
  /* ...all the other hooks you’re using... */
} = createRoomContext<ReactPresence, ReactStorage /* UserMeta, RoomEvent */>(client);

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('Store', useStore);
}
export default useStore;
