//routing page: is page where everything which is fixed and constant on any any page of the app
//is codes and placed here. like header, footer, side bar, etc
//and content of the pag which is changing (also called "child" component) is passed as a prop
//child component is ROUTED with react-router-dom hence RoutingPage is name of file

import {
    BrowserRouter as Router,
    Route,
    Link,
    Routes
} from "react-router-dom";
import CreateProject from "./CreateProject";
import HomePage from "./HomePage";
import Pricing from "./Pricing";
import HomeCard from "./ProjectCard";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../index";
import WritingDraft from "./WritingDraft";
import useStore from "../store";
import firebase from "firebase/compat/app";
import {Auth} from "../components/Auth";
import Setting from "./Setting";
import {useEffect, useState} from "react";
import ChatGPT from "./ChatGPT";



export default function RoutingPage() {


    const [projects, setProjects] = useState([])
    const isDarkMode = useStore(state => state.isDarkMode);
    const setDarkMode = useStore(state => state.setDarkMode);


        useEffect(() => {
        const projFirebase = getDocs(collection(db, "project_details")).then((projects) => {

            //@ts-ignore
            setProjects(projects.docs.map((project) => project.data()))



        });
    }, [])


    const user= useStore(state=>state.user);
    const [showAuthPopUp, setShowAuthPopUp] = useState(false);
    const setSignInisBig = useStore((state) => state.setSignInisBig);


    function handleClick() {
        // @ts-ignore
        if (user)
        {
            console.log("User is logged in")
        }
        else{
            console.log("No user")
            setShowAuthPopUp(true) //close pop-up of writing post
            setSignInisBig(true) //make sign in pop-up big
        }
    }

    function signOut() {
        firebase.auth().signOut().then(() => {
           console.log(" Sign-out successful.")
        }).catch((error) => {
            console.log("An error happened in sign out.")
        });
    }


    // @ts-ignore
    return (

        //header is always part of routing and body is the homepage, create project, etc
        <Router>
                <nav>
                    <ul>
                        <header className={""}>
                            <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

                            <div className="header fixed z-50">
                                <div className={`fixed top-0 border border-1 shadow-sm w-full px-5 py-2 flex justify-between items-center ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>
                                <div className="wsLogo flex flex-col items-center justify-center">
                                        <a href={'/'}>
                                            {/*<img src={(isDarkMode) ? './images/whiteLogo.png' : './images/logo.png'} className="w-44" alt={"Logo image"}></img></a>*/}
                                            <img src={(isDarkMode) ? `${window.location.origin}/images/whiteLogo.png` : `${window.location.origin}/images/logo.png`} className="w-44" alt={"Logo image"}></img>
                                        </a>
                                    </div>


                                    <div className="headerBtns">
                                        <Link to="/" className="bg-[#FAFBFA] font-normal text-[#2b2b2b] font-bold py-2 px-4 ml-4 rounded-full hover:bg-[#1CCAFF] hover:text-[#2b2b2b]">Home</Link>
                                        {/*pricing button is here*/}
                                        {/*<Link to="/pricing" className="bg-[#FAFBFA] font-normal text-[#2b2b2b] font-bold py-2 px-4 ml-5 rounded-full hover:bg-[#1CCAFF] hover:text-[#2b2b2b]">Pricing</Link>*/}

                                        {user ? (
                                          <a onClick={signOut} className="bg-[#1CCAFF] items-center font-normal text-[#18181b] font-bold py-2 px-4 ml-4 rounded-3xl hover:bg-[#000000] hover:text-[#FFFFFF]">
                                              Sign out</a>) : (
                                        <a onClick={handleClick} className="bg-[#1CCAFF] items-center font-normal text-[#18181b] font-bold py-2 px-4 ml-4 rounded-3xl hover:bg-[#000000] hover:text-[#FFFFFF]">
                                            Sign in</a>)}
                                        {!user && showAuthPopUp? <Auth action={() => setShowAuthPopUp(false)}/> : null}

                                    </div>

                                </div>
                            </div>
                        </header>
                    </ul>
                </nav>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/createProject" element={<CreateProject />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path={"/projects/:projectId/page/:pageId"} element={<WritingDraft />} /> //this line is for any link with projects and page but different id numbers that's why we use : this symbol
                    <Route path={"/projects/:projectId"} element={<WritingDraft />} />
                    <Route path={"/project/:projectId/setting"} element={<Setting />} />
                    <Route path={"/project/:projectId/chatgpt"} element={<ChatGPT />} />
                </Routes>

                {/*floating fixed circle button on right*/}
                <div className="fixed bottom-10 left-10 z-50">
                    {isDarkMode ? (
                        <button className={'bg-white w-14 border rounded-3xl'} onClick={() => setDarkMode(false)}>
                            <img src={`${window.location.origin}/images/moon.png`} className="w-14" alt={"Dark Mode"} />
                        </button>
                    ) : (
                        <button className={'bg-black w-14 border rounded-3xl'} onClick={() => setDarkMode(true)}>
                            <img src={`${window.location.origin}/images/sun.png`} className="w-14" alt={"Light Mode"} />
                        </button>
                    )}
                </div>

        </Router>
    );
}
