import React from 'react'
import useStore from "../store";
import Image from 'next/image';

export default function HomeCard({projName, projDescp, projIMG, pvt, id, defaultPageId} : {projName: string, projDescp: string, projIMG: string, pvt: boolean, id: string, defaultPageId: string}) {

    console.log(projIMG, projName, projDescp, pvt, id)
    const altImg = 'This is shit'
    const isDarkMode = useStore(state => state.isDarkMode);
    console.log("projIMG is this: ", projIMG)
    console.log("Type of projIMg is:", typeof projIMG)
    return (

        <>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin=""/>
            <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>

            <div className="flex flex-col justify-center w-72">

                    <div style={{height:"22rem"}} className={`overflow-x-hidden overflow-y-hidden rounded-lg shadow-md hover:border-[#1CCAFF] hover:border-4 hover:w-80 hover:overflow-x-visible hover:z-50 ${(isDarkMode) ? 'border border-slate-400 border-2 bg-slate-800' : 'bg-[#FAFBFA] border border-slate-500 border-2'}`} >

                        <a href={"/projects/"+ id } onClick={()=>console.log("project id is", id)}>
                            {(projIMG !=='' && projIMG !== undefined && projIMG !== null)?
                                (<div>
                                    <img className="rounded-t-lg object-cover w-full h-44"
                                      src={projIMG} alt={altImg}/>
                                </div>):
                                (isDarkMode)?
                                    (<div>
                                        <img className="rounded-t-lg w-full object-fill"
                                                    src={`${window.location.origin}/images/WriteSpaceProjectSlate900.png`} alt={altImg}/>
                                    </div>)

                                    :(<div>
                                        <img className="rounded-t-lg w-2/3 h-3/5 object-fill"
                                           src={`${window.location.origin}/images/WriteSpaceProjectSlate700.png`} alt={altImg}/>
                                    </div>)
                            }

                        </a>

                        <div className="p-5 w-full h-full overflow-x-hidden">
                            <a href={"/projects/"+id}>
                                <h5 className={`w-full mb-2 font-inter font-medium text-2xl overflow-x-hidden tracking-tight pt-2 pb-1 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>{projName}</h5>
                            </a>
                            <p className={`w-full mb-3 font-inter text-gray-500 overflow-x-hidden break-words ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>{projDescp}</p>

                        </div>

                    </div>
            </div>
        </>

    )
}
