import React from 'react'

export default function Pricing() {
  return (
    <div className='flex justify-center flex-col w-full'>


        <p className="flex justify-center font-inter font-bold text-4xl pt-28">Plans and Pricing</p>
        <p className="flex justify-center font-inter font-normal text-l text-gray-600 mt-2">
            Choose the best plan for your business
        </p>

    <div className='flex justify-center flex-row w-full'>
        
        
        <div className="flex justify-center">

                <div className="max-w-sm bg rounded-lg border border-black shadow-md mt-20">
                    <a href="#">
                    </a>

                    <div className="p-5">
                        <a href="#">
                            <h5 className="mb-2 font-inter font-medium text-2xl tracking-tight text-gray-900 pt-2 pb-1">FREE</h5>
                        </a>
                        <p className="mb-3 w-72 h-44 font-inter text-gray-500 h-fit overflow-hidden overflow-ellipsis break-words">Plan Details</p>
                        <a href="#" className="inline-flex items-center mt-5 py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                            Get Started
                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                </div>


                <div className="max-w-sm bg rounded-lg border border-black shadow-md mt-20">
                    <a href="#">
                    </a>

                    <div className="p-5">
                        <a href="#">
                            <h5 className="mb-2 font-inter font-medium text-2xl tracking-tight text-gray-900 pt-2 pb-1">FREE</h5>
                        </a>
                        <p className="mb-3 w-72 h-44 font-inter text-gray-500 h-fit overflow-hidden overflow-ellipsis break-words">Plan Details</p>
                        <a href="#" className="inline-flex items-center mt-5 py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                            Get Started
                            <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>
                    </div>
                </div>



            </div>
        </div>
    </div>
  )
}
