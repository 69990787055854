import styles from "./Header.module.css";
// import { useOthers } from "../../../liveblocks.config";
import Avatar from "./Avatar";
import Button from "./Button";
import SunIcon from "../icons/sun.svg";
import MoonIcon from "../icons/moon.svg";
import Tooltip from "./Tooltip";
import { Theme } from "../../../types";
import { useEffect, useState } from "react";
import { applyTheme } from "../../../utils/overrideRoomId";
import { LOCAL_STORAGE_THEME, USER_COLORS } from "../../../constants";
import UnderlineIcon from "../icons/underline.svg";
import useStore, {useOthers} from "../../../store";

export default function Header() {
  // const others = useStore((state) => state.liveblocks.others)
  const others = useOthers();
  const isDarkMode = useStore(state => state.isDarkMode);
  const [theme, setTheme] = useState<Theme | null>(
    localStorage.getItem(LOCAL_STORAGE_THEME) as Theme | null
  );

  useEffect(() => {
    if (!theme) {
      return;
    }

    localStorage.setItem(LOCAL_STORAGE_THEME, theme);
    applyTheme(theme);
  }, [theme]);

  return (
      //@ts-ignore
    // <header className={styles.header}>
      <header className="sticky top-0 left-0 right-0 z-20 backdrop-filter">
  {/*<div className={styles.container}>*/}
      <div className="sticky top-9 left-0 right-0 z-20 backdrop-filter">

      <div className={styles.left}>
          {/*<Tooltip content="Switch Theme">*/}
          {/*  <Button*/}
          {/*    appearance="ghost"*/}
          {/*    onClick={() => setTheme(theme === "light" ? "dark" : "light")}*/}
          {/*    ariaLabel="Switch Theme"*/}
          {/*    isSquare*/}
          {/*  >*/}
          {/*    {theme === "dark" ? <img src={SunIcon} alt="Sun icon"  /> : <img src={MoonIcon} alt="Moon icon" />}*/}
          {/*  </Button>*/}
          {/*</Tooltip>*/}
        </div>
        <div className={styles.right}>
          <div className={styles.avatars}>
            {others.map((user) => {
              const {
                // info: { imageUrl, name },
                connectionId,
              } = user;
              return (
                <Avatar
                  key={connectionId}
                  imageUrl={"https://i.pravatar.cc/300"}
                  name={"John Doe"}
                  color={USER_COLORS[connectionId % USER_COLORS.length]}
                />
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
}
