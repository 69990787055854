export const LIVEBLOCKS_PUBLIC_KEY = "pk_prod_s23g11sdPpXpqEBDTbCyT3kK48eSdSSRGOfFBG743iRHjfeL1Ns69c4f6FeBXQcZ";
export const DEFAULT_LIVEBLOCKS_ROOM_ID = "production-1";
export const CANVAS_WIDTH = 5000, CANVAS_HEIGHT = 3000;
export const RANDOM_POST_COLOURS = ["#DC2626", "#D97706", "#059669", "#7C3AED", "#DB2777"];
export const RANDOM_CURSOR_COLOUR = ["#DC2626", "#D97706", "#059669", "#7C3AED", "#DB2777"];
export const RANDOM_COMMENT_COLOUR = ["#DC2626", "#D97706", "#059669", "#7C3AED", "#DB2777"];
export const ZOOM_SPEED = 0.5;
export const ZOOM_STEP = 0.1;
export const ZOOM_MIN = 0.3;
export const ZOOM_MAX = 1;
export const CANVAS_BACKGROUND_COLOR = "rgba(178,255,223,0.8)";
export const CANVAS_BORDER_STYLE = '2px solid #000000'

export const CHAT_BUTTON = "/"
export const CANCEL_BUTTON = "q"
export const REACTION_BUTTON = "e"
export const CHATTING_CURSOR_COLOUR = "#7C3AED"
export const REACTIONS = ["👍", "🔥", "😍", "👀", "😱", "🙁"]
export const ADMIN_CHEAT_CODE = "hesoyam"
export const SECONDS_TO_SHOW_FEEDBACK = 90
export const DEFAULT_IMAGE_URL = "https://media.tenor.com/JvArM7ZO5JIAAAAM/bavatogay-ricardo-milos.gif"
export const DEFAULT_NAME = "Anonymous"

import { Format } from "./types";

export const PROSE_CONTAINER_ID = "prose_container";

export const LOCAL_STORAGE_THEME = "theme";

export const USER_COLORS = [
    "#0167B5",
    "#2FCABE",
    "#31B455",
    "#A322E8",
    "#CA672F",
    "#F8B301",
    "#FF1F73",
];

export const HOTKEYS: Record<string, Format> = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+s": "strikeThrough",
};

export const DEFAULT_TAB_NAME = "Tab 1";
