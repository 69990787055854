
import React, {useEffect} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui'
import useStore from "../store";
import {OnBoardingCard} from "./OnBoardingCard";
import {FIREBASE_LOGIN_UI_CONFIG} from "../firebase";

export const Auth = ({action}) => {
  const setUser = useStore((state) => state.setUser);
  let uiConfig = FIREBASE_LOGIN_UI_CONFIG(setUser);

  useEffect(() => {
    console.log('Start UI')
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(r => null)
    // @ts-ignore
    ui.start('#firebaseui-auth-container', uiConfig);
  }, [])

  function closePopUp() {
    // @ts-ignore
    action();
  }


  return (
      <div className={"fixed top-0 left-0 w-full h-full flex justify-center items-center z-100"}>
        <div className={`fixed top-0 left-0 w-full h-full bg-gray-500 opacity-50 z-100`}></div>
        <div className={`relative bg-white rounded-lg shadow dark:bg-gray-700 z-50 w-2/3 md:w-2/5 lg:w-1/3`}>
          <button onClick={closePopUp} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className={ "grid place-items-center p-4 md:p-6"}>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Sign in to Write Space</h3>
            <p className="text-gray-600 dark:text-gray-300">The collaborative writing space</p>
            <br/>
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" id="firebaseui-auth-container"></div>
          </div>
          {/*<OnBoardingCard/>*/}
        </div>
      </div>

  // <div className={"fixed top-60 z-90"}>
  //   <div  className={`backdrop-blur-sm overflow-y-auto overflow-x-hidden fixed z-50 w-2/12 h-3/5 md:inset-0 md:h-full block`}>
  //     <div className={ "relative grid place-items-center p-4 w-full h-full md:h-auto"}>
  //       <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
  //         <div className="py-6 px-6 lg:px-8 grid place-items-center">
  //           <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Sign in to Write Space</h3>
  //           <button onClick={closePopUp} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
  //             <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
  //             <span className="sr-only">Close modal</span>
  //           </button>
  //           <p className="text-gray-600 dark:text-gray-300">The collaborative writing space</p>
  //           <br/>
  //           <div className="relative bg-white rounded-lg shadow dark:bg-gray-700" id="firebaseui-auth-container"></div>
  //         </div>
  //         {/*<OnBoardingCard/>*/}
  //       </div>
  //     </div>
  //   </div>
  // </div>
  )
}
