
import "./App.css";
import TagManager from 'react-gtm-module';
import React, {useEffect, useState} from "react";
import useStore from "./store";
import {SECONDS_TO_SHOW_FEEDBACK} from "./constants";
import { getAnalytics } from "firebase/analytics";
import RoutingPage from "./ws-components/RoutingPage";
import { Toaster } from 'react-hot-toast';
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import {FIREBASE_CONFIG} from "./firebase";


TagManager.initialize({
    gtmId: 'GTM-MNNPTV8'
});

export default function App() {

  const [writing, setWriting] = useState(false)
  const initUser = useStore((state) => state.initUser); //this line is for the user to be initialized
  const activePost = useStore((state) => state.activePost);
  const [feedback, setFeedback] = useState(false);
  const [onBoarding, setOnBoarding] = useState(true);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        }
    }, []);

  initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(initializeApp(FIREBASE_CONFIG));
  const firebaseApp = initializeApp(FIREBASE_CONFIG);
  const provider = new GoogleAuthProvider();
  const auth = getAuth(firebaseApp);
  const user = useStore((state) => state.user);
  onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // @ts-ignore
          initUser(user);
          // ...
        } else {
          // User is signed out
          // ...
          // @ts-ignore
          initUser(null)
        }
      }
    );

    useEffect(() => {
    setTimeout(() => {
      initUser()
    }, 1000)
  }, [initUser])

  useEffect(() => {
    setTimeout(() => {
      setFeedback(true)
    }, SECONDS_TO_SHOW_FEEDBACK * 1000)
  }, [])

  return (
    <>
        {(isMobile)? (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-50 flex justify-center items-center">
                <div className="bg-slate-800 border border-[#1CCAFF] px-6 py-8 rounded-lg shadow-lg text-center">
                    <h2 className="text-xl text-[#FAFBFA] font-bold mb-4">Website not optimized for mobile</h2>
                    <p className="mb-4 text-[#FAFBFA]">This website is not optimized for mobile devices, yet. Please visit us on a laptop or desktop.</p>
                    {/*<button className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg" onClick={() => setIsMobile(false)}>Close</button>*/}
                </div>
            </div>
        ):

            <TooltipProvider>
                <div><Toaster /></div>
                {/* <ToastContainer
        position="top-right"
        autoClose={5000}
      /> */}

                <RoutingPage/>

                {/*{ activePost !== null ? <PostPopUp postId={activePost}></PostPopUp> : null }*/}
                {/*{ feedback ? <FeedbackPopUp action={() => setFeedback(false)}/> : null }*/}
                {/*{ onBoarding ? <OnBoardingPopUp action={() => setOnBoarding(false)}/> : null }*/}
                {/*{ writing ? <WritingPopUp action={() => setWriting(false)}/> : null }*/}
                {/*<WritingButton action={() => setWriting(true)} />*/}
                {/* <ScalingButtons />*/}
            </TooltipProvider>
        }

    </>
  );
}

