import React, {useEffect, useMemo, useState} from "react";
import "./css-files/light-theme.css";
import "./css-files/globals.css";
import "./css-files/prose.css";
import {Link, useParams} from "react-router-dom";
import EditorSection from "./Editor";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Canvas from "../components/Canvas";
import ActionPanel from "../components/ActionPanel";
import {DEFAULT_LIVEBLOCKS_ROOM_ID} from "../constants";
import {overrideRoomId, withLayout, withNodeId} from "../utils/overrideRoomId";
import useStore, {blockInitialValue} from "../store";
import WritingButton from "../components/WritingButton";
import WritingPopUp from "../components/WritingPopUp";
import {RoomProvider} from "../store"
import {LiveList, LiveObject} from "@liveblocks/client";
import {Loading} from "./liveblocksCW/components";
import ChatGPT from "./ChatGPT";
import Scoreboard from "./Scoreboard";
import {withReact} from "slate-react";
import {createEditor, Editor, Element, Node, Point, Range, Transforms} from "slate";
import {BlockType, CustomElement} from "../types";
import {Auth} from "../components/Auth";
import EditProjectDetails from "./EditProjectDetails";

const SHORTCUTS: Record<string, BlockType> = {
    "*": BlockType.BulletedList,
    "-": BlockType.BulletedList,
    "+": BlockType.BulletedList,
    "#": BlockType.H1,
    "##": BlockType.H2,
    "###": BlockType.H3,
    "[]": BlockType.ToDo,
};
// roomId = overrideRoomId(roomId);

export default function WritingDraft() {

    const user = useStore(state => state.user)
    const [writing, setWriting] = useState(false)
    const [showAuthPopUp, setShowAuthPopUp] = useState(true)
    const [editorReloader, setEditorReloader] = useState(false)
    // const projectId= useStore(state => state.projectId)
    // const pageId= useStore(state => state.pageId)
    const params = useParams();
    const projectId = params.projectId
    const pageId = params.pageId
    const isDarkMode = useStore(state => state.isDarkMode);
    const setSignInisBig = useStore((state) => state.setSignInisBig);
    const [showChatGPT, setShowChatGPT] = useState(true);
    const [showScoreboard, setShowScoreboard] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);


    const initialStorage = () => ({

        "blocks": new LiveList(blockInitialValue),
        "shapes": new LiveObject({})
    });


    useEffect(() => {
        setEditorReloader(true)
        setTimeout(() => {
            setEditorReloader(false)
        }, 200)
    }, [projectId, pageId])

    const chatGPTClicked = () => {
        console.log("Chat GPT clicked")
        return (
            <div className={'fixed top-60 w-3.5/12'}>

            </div>
        )
    }

    const deleteChatGPT = (projectId) => {
        console.log("delete chat gpt clicked")
        const db = firebase.firestore();
        db.collection("chatgpt_grammar_chat").where('project_id', '==', projectId).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    doc.ref.delete().then(() => {
                        console.log("ChatGPT Document successfully deleted! Of project id- ", projectId);
                        window.location.href = "/home";
                    });
                });
            })
            .catch(error => {
                console.error("Error deleting document: ", error);
            });
    }

    const deleteProject = (projectId) => {
        console.log("delete project clicked")
        const db = firebase.firestore();
        db.collection("project_details").where('id', '==', projectId).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    doc.ref.delete().then(() => {
                        console.log("Document successfully deleted!");
                        window.location.href = "/home";
                    });
                });
                deleteChatGPT(projectId)
            })
            .catch(error => {
                console.error("Error deleting document: ", error);
            });

    }

    const handleCloseModal = () => {
        setShowEditProjectModal(false);
    };


    return (
        <div className={'top-0 z-50'}>
            {user === null ? <Auth action={() => setShowAuthPopUp(false)}/> : null}

            <RoomProvider id={projectId + '+' + pageId} initialStorage={initialStorage}>

                <div id={'writingPopUp'} className="writingDraft flex flex-row">

                    <div
                        className={`fixed border-r border-slate-600 shadow-md left-0 top-0 w-30 h-screen z-50 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>
                        <div className="list-container h-full overflow-y-scroll shadow-md">
                            <img
                                src={(isDarkMode) ? `${window.location.origin}/images/whiteLogo.png` : `${window.location.origin}/images/logo.png`}
                                className="w-36 mt-5" alt={"Logo image"}/>
                            <ul className="icon-list mt-12">

                                <li className={' HomeIcon flex flex-col items-center justify-center w-36 p-2 -ml-1'}>
                                    <a href={`/home`}>
                                        <img
                                            src={(isDarkMode) ? `${window.location.origin}/images/blueHomeIcon.png` : `${window.location.origin}/images/blackHomeIcon.png`}
                                            className="w-9" alt={"Home"}/>
                                        <button
                                            className={`text-xs mt-1 ${isDarkMode ? 'text-[#FAFBFA]' : 'text-black'}`}>
                                            <p>Home</p></button>
                                    </a>
                                </li>
                                <li className={'KavunAiAssistant flex flex-col items-center justify-center mt-2 w-36 p-2 -ml-1'}>
                                    <button
                                        onClick={
                                            () => {
                                                setShowChatGPT(true)
                                                setShowScoreboard(false)
                                            }}>
                                        <img
                                            src={(isDarkMode) ? `${window.location.origin}/images/whiteWizard.png` : `${window.location.origin}/images/blackWizard.png`}
                                            className={`w-14`} alt="AI Assistant"/>

                                    </button>
                                    <button className={`text-xs mt-2 ${isDarkMode ? 'text-[#FAFBFA]' : 'text-black'}`}>
                                        <p>Kavun AI Assistant</p></button>
                                </li>

                                <li className={'scoreBoard flex flex-col items-center mt-2 justify-center w-36 p-2 -ml-2'}>
                                    <button onClick={
                                        () => {
                                            setShowChatGPT(false)
                                            setShowScoreboard(true)
                                        }}>
                                        <img
                                            src={(isDarkMode) ? `${window.location.origin}/images/blueScore.png` : `${window.location.origin}/images/blackScore.png`}
                                            className="w-14 -mb-2" alt={"Score"}/>
                                    </button>
                                    <button
                                        className={`text-xs ml-2 -mt-1 ${isDarkMode ? 'text-[#FAFBFA]' : 'text-black'}`}>
                                        <p>Scores</p></button>
                                </li>

                                <li className={'editProject flex flex-col items-center justify-center mt-5 w-36 p-2 -ml-1'}>
                                    <button
                                        onClick={() => {
                                            setShowEditProjectModal(true);
                                            console.log("edit project clicked")
                                            console.log(showEditProjectModal, "is value of showDeleteModal const")
                                        }}>
                                        <img
                                            src={(isDarkMode) ? `${window.location.origin}/images/editBlue.png` : `${window.location.origin}/images/edit.png`}
                                            className={`w-10`} alt="Edit"/>

                                    </button>
                                    <button onClick={() => {
                                        setShowEditProjectModal(true);
                                        console.log("edit project clicked")
                                        console.log(showEditProjectModal, "is value of showEditProjectModal const")
                                    }} className={`text-xs mt-2 ${isDarkMode ? 'text-[#FAFBFA]' : 'text-black'}`}>
                                        <p>Edit Project</p>
                                    </button>
                                </li>

                                <li className={'DeleteProject flex flex-col items-center justify-center mt-5 w-36 p-2 -ml-1'}>
                                    <button
                                        onClick={() => {
                                            setShowDeleteModal(true);
                                            console.log("delete project clicked")
                                            console.log(showDeleteModal, "is value of showDeleteModal const")
                                        }}>
                                        <img
                                            src={(isDarkMode) ? `${window.location.origin}/images/blueTrash.png` : `${window.location.origin}/images/blackTrash.png`}
                                            className={`w-10`} alt="Delete"/>

                                    </button>
                                    <button onClick={() => {
                                        setShowDeleteModal(true);
                                        console.log("delete project clicked")
                                        console.log(showDeleteModal, "is value of showDeleteModal const")
                                    }} className={`text-xs mt-2 ${isDarkMode ? 'text-[#FAFBFA]' : 'text-black'}`}>
                                        <p>Delete project</p>
                                    </button>
                                </li>

                                {/*<li className={'flex flex-col items-center justify-center w-36 p-2 -ml-2'}>*/}
                                {/*    <a href="#">*/}
                                {/*        <img src="icon-4.png" alt="Icon 4" />*/}
                                {/*        <span>Icon 4</span>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <a href="#">*/}
                                {/*        <img src="icon-5.png" alt="Icon 5" />*/}
                                {/*        <span>Icon 5</span>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>

                    {showDeleteModal &&
                        <div
                            className="fixed w-full h-full top-0 left-0 flex items-center justify-center bg-slate-800 bg-opacity-80 bg-[#FAFBFA]"
                            style={{zIndex: 90}}>
                            <div className="rounded-lg shadow-lg p-4 bg-[#FAFBFA]" style={{zIndex: 999}}>
                                <p className="mb-4 text-lg">Are you sure you want to delete the project?</p>
                                <p className="mb-4 text-sm">This action cannot be undone. Click delete to proceed</p>
                                <div className="flex justify-end">
                                    <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
                                            onClick={() => {
                                                deleteProject(projectId);
                                                setShowDeleteModal(false)
                                            }}>Delete
                                    </button>
                                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
                                            onClick={() => setShowDeleteModal(false)}>No
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {showEditProjectModal &&
                        <div
                            className="fixed w-full h-full top-0 left-0 flex items-center justify-center bg-slate-800 bg-opacity-80"
                            style={{zIndex: 90}}>
                            <div className="bg-[#FAFBFA] rounded-lg shadow-lg p-4 h-3/5 w-6/12" style={{zIndex: 999}}>
                                <div className={'flex w-full items-right justify-end'}>
                                    <button onClick={()=>setShowEditProjectModal(false)}>
                                        <img src={`${window.location.origin}/images/cross.png`} alt="close icon" className={'w-7 h-7'}/>
                                    </button>
                                </div>
                                <EditProjectDetails projectId={projectId} handleCloseModal={handleCloseModal} />
                            </div>
                        </div>
                    }


                    <div
                        className={`sideMenuBar fixed h-screen z-50 top-0 left-30 w-5.1/12 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'} border-r border-slate-600`}>

                        {showChatGPT ?
                            <div className={`fixed top-0 w-3.5/12 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>
                                <ChatGPT/>
                            </div> : null}

                        {showScoreboard ?
                            <div className={`fixed top-0 w-3.5/12 ${isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]'}`}>
                                <Scoreboard/>
                            </div> : null
                        }

                    </div>

                    <div
                        className={'fixed overflow-x-hidden h-screen w-9/12 z-50 top-0 left-138 ' + (isDarkMode ? 'bg-slate-800' : 'bg-[#FAFBFA]') + ' border-l border-slate-600'}>
                        <div className="overflow-y-scroll overflow-x-hidden mr-12">
                            {editorReloader ? <Loading/> : <EditorSection roomId={projectId + '+' + pageId}/>}
                        </div>
                    </div>


                    {/*<div className="fixed bg-[#FAFBFA] shadow-md left-0 top-10 w-36 h-screen z-20">*/}
                    {/*    <div className="flex flex-col items-center justify-center">*/}
                    {/*        <button className={} to={''}>*/}
                    {/*            <img/>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/*<div className={"fixed top-16 left-124 z-600"}>*/}
                    {/*  <div className={''}><Canvas/></div>*/}
                    {/*  <div className={'z-600 top-0 left-0'}>*/}
                    {/*    <ActionPanel/>*/}
                    {/*  </div>*/}
                    {/*  {writing ? <WritingPopUp action={() => setWriting(false)}/> : null}*/}
                    {/*  <WritingButton action={() => setWriting(true)}/>*/}
                    {/*</div>*/}

                </div>
            </RoomProvider>
        </div>
    )
}


