import React from "react";
import {useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import functions = firebase.functions;
import {storage} from "../index";


export default function EditProjectDetails(props: { projectId: string , handleCloseModal: any}) {

    const [Image, setImage] = useState<File | null>(null);
    const [projectName, setProjectName] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [projectDescp, setProjectDescp] = useState("");
    const docRef = firebase.firestore().collection("project_details").where('id', '==', props.projectId);

    useEffect(() => {
        const fetchProject = async () => {
            const db = firebase.firestore()
            db.collection("project_details").where('id', '==', props.projectId).get().then((querySnapshot) => {
                const project = querySnapshot.docs[0];
                const projectData = project.data();
                setProjectName(projectData.project_name);
                setProjectDescp(projectData.project_descp);
                console.log("projectData.isPrivate is: ", projectData.isPrivate);
                setIsPrivate(projectData.isPrivate);
            });
        };
        fetchProject().then(r => console.log("Project fetched, here is outcome: ", r));
    }, [props.projectId]);

    const deleteImageFromStorage = async () => {
        const imageRef = firebase.storage().ref().child('/images/' + props.projectId);
        const querySnapshot = await docRef.get();
        const project = querySnapshot.docs[0];
        const projectData = project.data();
        const imageURL = projectData.project_img;

        if (imageURL !== "") {
            console.log("imageRef is: ", imageRef);
            await imageRef.delete();
        }
    };


    let fireBaseUrl = "";

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
            await deleteImageFromStorage();
            const uploadIMG = storage.ref(`/images/${props.projectId}`).put(event.target.files[0]);
            const uploadPromise = new Promise((resolve, reject) => {
                uploadIMG.on('state_changed', resolve, reject, async () => {
                    fireBaseUrl = await storage.ref('images').child(props.projectId).getDownloadURL();
                    try {
                        await docRef.get().then((data)=>{
                            if (data.size === 1) {
                                const docRef = data.docs[0].ref;
                                docRef.update({
                                    project_img: fireBaseUrl,
                                });
                            }
                        })

                    } catch (err) {
                        console.log('Error in updating project image is: ', err);
                    }
                });
            });
            await uploadPromise;
        }
    };


    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setProjectDescp(event.target.value);
    };


    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        console.log("privacy is: ", isPrivate);

        docRef.get().then((querySnapshot) => {
            if (querySnapshot.size === 1) {
                const docRef = querySnapshot.docs[0].ref;
                if (projectName) {
                    docRef.update({
                        project_name: projectName,
                    }).then(() => {
                        console.log('Document updated successfully!');
                    }).catch((error) => {
                        console.error('Error updating document:', error);
                    });
                }
                if (projectDescp) {
                    docRef.update({
                        project_descp: projectDescp,
                    }).then(() => {
                        console.log('Document updated successfully!');
                    }).catch((error) => {
                        console.error('Error updating document:', error);
                    });
                }
                if (isPrivate) {
                    docRef.update({
                        isPrivate: isPrivate,
                    }).then(() => {
                        console.log('Document updated successfully!');
                    }).catch((error) => {
                        console.error('Error updating document:', error);
                    });
                }
                if (isPrivate !== null) {
                    docRef.update({
                        isPrivate: isPrivate,
                    }).then(() => {
                        console.log('Document updated successfully!');
                    }).catch((error) => {
                        console.error('Error updating document:', error);
                    });
                }
                if(fireBaseUrl !== ""){
                    docRef.update({
                        project_img: fireBaseUrl,
                    }).then(() => {
                        console.log('Document updated successfully!');
                    }).catch((error) => {
                        console.error('Error updating document:', error);
                    });
                }
            } else {
                console.log('Query returned no documents!' + querySnapshot.size)
                console.log('Query returned' + querySnapshot)
                console.error('Query returned more than one document!');
            }
        }).catch((error) => {
            console.error('Error querying for documents:', error);
        });
       props.handleCloseModal();
    };


    return (
        <form onSubmit={handleFormSubmit} className={''}>
            <div className={'mt-3'}>
                <label htmlFor="title">Project name</label>
                <div className="w-full">
                    <input
                        className='bg-[#FAFBFA] border border-[#000000] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
                        id={'projectName'}
                        placeholder={projectName}
                        value={projectName}
                        onChange={handleTitleChange}
                        name="projectName" //important
                    />
                </div>
            </div>

            <div className={'mt-5'}>
                <label htmlFor="description">Project Description:</label>
                <div className="w-full">
                    <input
                        className='bg-[#FAFBFA] border border-[#000000] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5'
                        id={'projectDescp'}
                        placeholder={projectDescp}
                        value={projectDescp}
                        onChange={() => handleDescriptionChange}
                        name="projectDescp" //important
                    />
                </div>
            </div>


            <div className={'mt-6'}>
                <label className={'inline-flex relative items-left cursor-pointer'}>
                    <input type="checkbox" name={"privacy"} onChange={() => setIsPrivate(!isPrivate)}
                           className={"sr-only peer"}/>
                    <div
                        className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#1CCAFF] rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-black peer-checked:after:bg-[#1CCAFF] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                    {isPrivate ? (
                        <span className={"ml-3 text-sm font-medium text-black"}>Private</span>) : (
                        <span className="ml-3 text-sm font-medium text-[#18181b]">Public</span>)}
                </label>
            </div>

            <div className={'mt-3 flex flex-col'}>
                <label htmlFor="image" className={'mb-1'}>Project Image</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageChange}/>
            </div>

            <button
                className='bg-[#1CCAFF] mt-5 items-center font-normal text-[#18181b] font-bold py-2 px-4 rounded-xl hover:bg-[#000000] hover:text-[#FFFFFF]'
                type="submit">Save Changes
            </button>
        </form>
    )
}
