import {db} from "../index";
import {collection, DocumentData, getDocs, orderBy, query, where} from "firebase/firestore";
import firebase from "firebase/compat/app";
import {uuidv4} from "@firebase/util";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import "firebase/firestore";

export const getAllTabsForPage = async (projectId: string) => {
  const tabsCollection = collection(db, "project_tabs");
  const tabsQuery = query(tabsCollection, where("project_id", "==", projectId), orderBy("created", "asc"));
  const tabs = await getDocs(tabsQuery)
  console.log("Tabs: ", tabs)
  return tabs.docs.map((doc) => doc.data())
}

export const getProject = async (projectId: string) => {
  const projectCollection = collection(db, "project_details");
  console.log("Project ID 333: ", projectId)
  const projectQuery = query(projectCollection, where("id", "==", projectId));
  const projects = await getDocs(projectQuery)
  if (projects.docs.length == 0) {
    throw new Error("Project not found")
  }
  console.log("Project: ", projects)
  return projects.docs[0]
}

export const getChatGPTMessages = async (projectId: string, messages: any) => {
    const chatCollection = collection(db, "chatgpt_grammar_chat");
    const chatQuery = query(chatCollection, where("project_id", "==", projectId));
    const chats = await getDocs(chatQuery)
    if (chats.docs.length == 0) {
       await updateMessages(projectId, messages)
    }
    return chats.docs[0]
}

export const createNewTab = async (projectId: string, tabName: string) => {
  const getFirebase = firebase.firestore();
  const newTabId = uuidv4()

  console.log("New tab id: ", tabName)
  await getFirebase.collection("project_tabs").add({
    project_id: projectId,
    name: tabName,
    pageID: newTabId,
    created: Math.round((new Date()).getTime() / 1000)
  })
}

export const createGrammarChat = async (projectId: string, messages: any) => {
  const getFirebase = firebase.firestore();
  const chatId = uuidv4()

  console.log("Grammar chat id is: ", chatId, " for Project id: ", projectId)
  await getFirebase.collection("chatgpt_grammar_chat").add({
    project_id: projectId,
    chatId: chatId,
    created: Math.round((new Date()).getTime() / 1000),
    messages: messages
  }).then((docRef) => {
    console.log("Document written with ID: ", docRef.id);
  })
}


export const updateMessages = async (projectId: string, messages: any) => {
  const oldGPTMessages = collection(db, "chatgpt_grammar_chat");
  console.log("Project ID is: ", projectId)
  const chatQuery = query(oldGPTMessages, where("project_id", "==", projectId));
  const chats = await getDocs(chatQuery)
  if (chats.docs.length == 0) {
    await createGrammarChat(projectId, messages)
    console.log("As no old chat found, new chat doc is created")
    const newChatDoc = collection(db, "chatgpt_grammar_chat");
    const findNewDoc = query(newChatDoc, where("project_id", "==", projectId));
    const newChats = await getDocs(findNewDoc)
    console.log("New Chat History: ", newChats.docs[0])
    return(newChats.docs[0])
  }
  console.log("Old Chat History: ", chats)
  const oldChatDoc = chats.docs[0]
  console.log("Old Chat Doc before updating messages array: ", oldChatDoc)
  const newMessages= {
    'messages': messages
  }
  const data= firebase.firestore()
  const oldMessagesDoc = data.collection('chatgpt_grammar_chat').doc(chats.docs[0].id);
  console.log("New Messages: ", newMessages)
  console.log("Old Messages Doc: ", oldMessagesDoc)
  console.log("Old message history doc id is: ", chats.docs[0].id)
  const updatedDoc= await updateDoc(oldMessagesDoc, newMessages)
  return updatedDoc
}
